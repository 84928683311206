import * as React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Content from '../components/content';



const Page = ({data}) => {
  return <>
    <Layout>
      <Seo title={data.strapiPages.title} />
      <h1>{data.strapiPages.title}</h1>

      <Content data={data.strapiPages.content} />
    </Layout>
  </>;
}

export default Page;

export const query = graphql`
  query($id: String) {
    strapiPages(id: { eq: $id }) {
      content
      url
      title
      id
    }
  }
`;